import axios from 'axios'
import log from 'utils/logger'
import cache from 'memory-cache'
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { FC } from 'react'
import { StoryData } from 'storyblok-js-client'
import { AppStoreData } from 'types/AppStoreData'
import { gql } from '@apollo/client'
import { getStoryblokApi, useStoryblokState } from '@storyblok/react'
import Layout from '../components/Layout'
import Page from '../components/storybloks/Page'
import { NavigationHeader, PageAlternateLink, StoryBlokLink, TopBanner, useLink } from '../context/LinkContext'
import ChristmasDeliveryDate from '../types/ChristmasDeliveryDates'
import DeliveryInfo from '../types/DeliveryInfo'
import PaymentOption from '../types/PaymentOption'
import JobylonJob from '../types/JobylonJob'
import StoryDataInfo from '../types/StoryDataInfo'
import client from '../utils/api-client'
import { Product, getStoreData } from 'utils/get-store-data'
import { builder } from '@builder.io/react'
import { useRouter } from 'next/router'
// import { BuilderContent } from '@builder.io/sdk/dist/src/types/content'
import { BuilderContent } from '@builder.io/sdk'
import { ExtendendBuilderContent } from 'types/BuilderTypes'
import {
  CmsPath,
  ModelType,
  PathParam,
  aggregateBuilderPaths,
  getAllBuilderPages,
  getNavHeaders,
  getPageAlternatesForBuilder,
  getPathsFromStoryblok,
  getTopBanners,
  isParentPage,
} from 'utils/cms-helper'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type SbLink = {
  id: number
  slug: string
  name: string
  alternates: {
    path: string
    lang: string
    translated_slug: string
  }[]
}
type PropTypes = {
  builderContent?: BuilderContent
  pageAlternatesForBuilder?: PageAlternateLink[]
  slugStory?: StoryData
  layoutStory: StoryData
  preview?: boolean
  links?: StoryBlokLink[]
  jobs?: JobylonJob[]
  deliveryInfo?: DeliveryInfo
  paymentOptions?: PaymentOption[]
  appStoreData?: AppStoreData
  storyDataInfo?: StoryDataInfo
  christmasDeliveryDates?: ChristmasDeliveryDate
  storeData?: Product[]
  contentType: ModelType
  navHeaders?: NavigationHeader[]
  topBanners?: TopBanner[]
}

const Slug: FC<PropTypes> = ({
  slugStory,
  layoutStory,
  appStoreData,
  builderContent,
  pageAlternatesForBuilder,
  contentType,
  navHeaders,
  topBanners,
}) => {
  const slugStoryWithPreview = useStoryblokState(slugStory)

  const router = useRouter()
  const host = 'https://onceupon.photo'
  const { getPageAlternates } = useLink()
  // if we have pageAlternates from Builder we're fine, otherwise we get them from Storyblok
  const pageAlternates: PageAlternateLink[] = pageAlternatesForBuilder ?? getPageAlternates(router.asPath, host, router.defaultLocale)

  return (
    <Layout content={layoutStory?.content} pageAlternates={pageAlternates} navHeaders={navHeaders} topBanners={topBanners}>
      {builderContent ? (
        <Page
          builderContent={builderContent}
          appStoreData={appStoreData}
          pageAlternatesForBuilder={pageAlternatesForBuilder}
          contentType={contentType}
        />
      ) : (
        <Page storyblokContent={slugStoryWithPreview?.content} appStoreData={appStoreData} />
      )}
    </Layout>
  )
}
const getAppStoreData = async (): Promise<AppStoreData> => {
  const url = 'http://itunes.apple.com/lookup?id=1187208815&country=se'
  const cachedResponse = cache.get(url)
  let data
  if (cachedResponse) {
    data = cachedResponse
  } else {
    const response = await axios.get('http://itunes.apple.com/lookup?id=1187208815&country=se')
    data = response.data.results[0]
    cacheData(url, data)
  }
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: 'Once Upon | Photo Books',
    operatingSystem: 'iOS',
    applicationCategory: 'LifestyleApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: (Math.round(data.averageUserRating * 10) / 10).toString(),
      ratingCount: data.userRatingCount.toString(),
    },
    offers: {
      '@type': 'Offer',
      price: '0',
    },
  }
  return structuredData
}
const getStoryDataInfo = async (slug: string): Promise<StoryDataInfo> => {
  const storyblokApi = getStoryblokApi()
  if (!slug.startsWith('stories')) return null
  const { data: authors } = await storyblokApi.get('cdn/stories?starts_with=authors/')
  const { data: categories } = await storyblokApi.get('cdn/stories?starts_with=categories/')
  return {
    authors: authors,
    categories: categories,
  }
}
const getJobList = async (slug: string): Promise<JobylonJob[]> => {
  if (slug !== 'work-with-us' && slug !== 'WIP_work-with-us') return null
  const response = await axios.get('https://feed.jobylon.com/feeds/1f7135dcb7d94bf592a6281c8201c54b/?format=json')
  return response.data
}
const cacheData = (key: string, data: unknown): void => {
  const hours = 24
  const hoursInMs = hours * 1000 * 60 * 60
  cache.put(key, data, hoursInMs)
}
const getDeliveryInfo = async (language: string, region: string, slug: string): Promise<DeliveryInfo> => {
  if (slug !== 'delivery-payment') return null
  // if Canada get delivery options for US
  const lang = region === 'CA' ? 'en_US' : `${language}_${region}`
  const { data } = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query {
        listDeliveryInfo(country_code: ${region}, lang: ${lang}, platform: WEB) {
          checkout_shipment_info
          countries {            
            country_code
            country_name
            delivery_copy
            max_delivery_time
            min_delivery_time
          }
          home_story_shipping_body
        }
      }
    `,
  })
  return data.listDeliveryInfo as DeliveryInfo
}
export const getProductInfo = async (locale: string): Promise<Product[]> => {
  return await getStoreData(locale)
}
export const getPaymentOptions = async (language: string, region: string): Promise<PaymentOption[]> => {
  // if Canada get payment options for US
  const lang = region === 'CA' ? 'en_US' : `${language}_${region}`
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: gql`
        query {
          getPaymentOption(lang: ${lang}, locale: "${region}", userId: "1") {
            description
            image {
              height
              url
              width
            }
            name
            type
          }
        }
      `,
  })
  return data.getPaymentOption as PaymentOption[]
}
const getChristmasDates = async (language: string, region: string, slug: string): Promise<ChristmasDeliveryDate> => {
  if (slug !== 'christmas-delivery') return null
  // if Canada get christmas delivery dates for US
  const lang = region === 'CA' ? 'en_US' : `${language}_${region}`
  try {
    const { data } = await client.query({
      query: gql`
        query {
          listChristmasDeliveryInfo(lang: ${lang}) {
            home_banner_christmas_orders
            home_story_xmasdelivery_body
            msg_inline_christmas
            countries {
              country
              country_code
              last_order_date,
            }
          }
        }
      `,
    })
    return data.listChristmasDeliveryInfo as ChristmasDeliveryDate
  } catch (err) {
    return null
  }
}
const devEnvironment = process.env.NODE_ENV === 'development'
const previewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
const vercelLinkInGithubEnvironment = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
///
/// Default cache in production. No cache in development
///
const builderCache = prodEnvironment ? {} : { cacheSeconds: 0, staleCacheSeconds: 0 }
const releaseBranch = (): string => {
  // to fetch data from a storyblok release branch, set the RELEASE_ID in your .env file
  if (devEnvironment) {
    return process.env.RELEASE_ID
  } else if (vercelLinkInGithubEnvironment) {
    // env in Vercel, can be set to specific branch
    return process.env.NEXT_PUBLIC_STORYBLOK_RELEASE_ID
  }
}
const builderLimit = 50
export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext): Promise<GetStaticPropsResult<PropTypes>> => {
  const storyblokApi = getStoryblokApi()
  let dynamicSlug = context.params.slug || ['home']
  const layoutSlug = 'layout'
  const language = context.locale.substring(0, 2)
  const region = context.locale.substring(3).toUpperCase()
  let urlPath = ''
  if (Array.isArray(dynamicSlug)) {
    urlPath = '/' + dynamicSlug.join('/')
    dynamicSlug = dynamicSlug.join('/')
  }
  const params = {
    version: devEnvironment ? 'draft' : 'published',
    cv: null,
    resolve_links: 'url',
    resolve_relations:
      'blogpost.author,blogpost.categories,post_list.posts,form_dropdown_item.input_fields,form_section.global_fields,article_list.articles,blogpost_list.blogposts',
    from_release: releaseBranch(),
    language: context.locale,
  }
  if (context.preview) {
    params.version = 'draft'
    params.cv = Date.now()
  }
  let builderContent: ExtendendBuilderContent = null
  let pageAlternatesForBuilder: PageAlternateLink[] = null
  let contentType: 'page' | 'generic-page' = 'page'
  const localeLowercase = `${language}-${region.toLowerCase()}`
  const locale = region === 'CA' ? `${language}-${region.toUpperCase()}` : localeLowercase
  // look for the slug/page in Builder.io
  try {
    builderContent = await builder
      .get('page', {
        ...builderCache,
        userAttributes: { urlPath, locale },
        options: { locale: localeLowercase, includeUnpublished: !prodEnvironment && !previewEnvironment },
      })
      .toPromise()
    if (!builderContent) {
      contentType = 'generic-page'
      builderContent = await builder
        .get('generic-page', {
          ...builderCache,
          userAttributes: { urlPath, locale },
          options: { locale: localeLowercase, includeUnpublished: !prodEnvironment && !previewEnvironment },
        })
        .toPromise()
    }
    if (builderContent) {
      const isParent = isParentPage(builderContent)
      const parentId = isParent ? builderContent.id : builderContent?.data?.reference?.id
      const fields = 'id,meta.originalContentId,query,data.reference.id'
      const builderPages = await getAllBuilderPages({
        limit: builderLimit,
        fields,
        builderCache,
        contentType: contentType,
        includeUnpublished: !prodEnvironment && !previewEnvironment,
      })
      pageAlternatesForBuilder = getPageAlternatesForBuilder({ content: builderPages, originalContentId: parentId })
    }
  } catch (err) {
    log.error('BUILDER ERROR', err)
  }
  let apiResult = null
  let slugData = null
  let jobData = null
  try {
    apiResult = await storyblokApi.get(`cdn/stories/${dynamicSlug}`, params)
    const { data: _slugData } = apiResult || {}
    if (!builderContent) {
      // didn't find the slug/page in Builder.io, so we try Storyblok
      slugData = _slugData || null
    } else {
      jobData = await getJobList(_slugData?.story?.slug)
    }
  } catch (err) {}

  const navHeaders = await getNavHeaders(builderCache, locale)
  const topBanners = await getTopBanners(builderCache, locale)

  const { data: layout } = await storyblokApi.get(`cdn/stories/${layoutSlug}`, params)
  const { data: links } = await storyblokApi.get('cdn/links/')
  if (!slugData && !builderContent) {
    return {
      props: {
        slugStory: slugData ? slugData.story : false,
        layoutStory: layout ? layout.story : false,
        contentType: contentType,
      },
      notFound: true,
      revalidate: 2,
    }
  }
  const defaultBuilderSlug = pageAlternatesForBuilder?.find((l) => l.hreflang === 'x-default')?.href
  return {
    props: {
      builderContent: builderContent || null,
      pageAlternatesForBuilder: pageAlternatesForBuilder,
      slugStory: slugData ? slugData.story : false,
      layoutStory: layout ? layout.story : false,
      preview: context.preview || false,
      links: Object.values(links?.links ?? {}),
      jobs: jobData,
      appStoreData: await getAppStoreData(),
      deliveryInfo: slugData
        ? await getDeliveryInfo(language, region, slugData?.story?.slug)
        : defaultBuilderSlug?.includes('delivery-payment')
        ? await getDeliveryInfo(language, region, 'delivery-payment')
        : null,
      paymentOptions: await getPaymentOptions(language, region),
      storyDataInfo: slugData ? await getStoryDataInfo(slugData?.story?.default_full_slug) : null,
      christmasDeliveryDates: slugData
        ? await getChristmasDates(language, region, slugData?.story?.slug)
        : defaultBuilderSlug?.includes('christmas-delivery')
        ? await getChristmasDates(language, region, 'christmas-delivery')
        : null,
      storeData: await getProductInfo(context.locale),
      ...(await serverSideTranslations(`${language}-${region}`, ['common'])),
      contentType: contentType,
      navHeaders: navHeaders ?? null,
      topBanners: topBanners ?? null,
    },
    revalidate: prodEnvironment ? 300 : 2,
  }
}
export const getPathsFromBuilder = async (): Promise<CmsPath> => {
  const fields = 'data.url,query'
  const pages = await getAllBuilderPages({
    limit: builderLimit,
    fields,
    builderCache,
    contentType: 'page',
    includeUnpublished: !prodEnvironment && !previewEnvironment,
  })
  const reducedPages = pages.reduce(aggregateBuilderPaths, [] as PathParam[])
  return reducedPages?.map((obj) => {
    const urls = obj.url.slice(1).split('/')
    return { params: { slug: [...urls] }, locale: obj.locale?.toLowerCase() ?? 'en-gb' }
  })
}
export const getStaticPaths: GetStaticPaths = async () => {
  if (!prodEnvironment) {
    return {
      paths: [],
      fallback: 'blocking',
    }
  }
  const storyblokApi = getStoryblokApi()
  const storyblokPaths = await getPathsFromStoryblok(storyblokApi)
  const builderPaths = await getPathsFromBuilder()
  const allPaths = [...storyblokPaths, ...builderPaths]
  return {
    paths: allPaths,
    fallback: 'blocking',
  }
}
export default Slug
