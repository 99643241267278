import { NavigationHeader, PageAlternateLink, StoryBlokLinkAlternate, TopBanner } from 'context/LinkContext'
import { ExtendendBuilderContent, LanguageVariant } from 'types/BuilderTypes'
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher'
import Storyblok from 'storyblok-js-client'
import builder from '@builder.io/react'
import { BuilderContent } from '@builder.io/sdk'

const devEnvironment = process.env.NODE_ENV === 'development'
const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

type LanguageVariantProps = {
  content: ExtendendBuilderContent[]
  originalContentId: string
  subPath?: string
}
type ConvertProps = {
  variants: LanguageVariant[]
  host: string
}

export type PathParam = {
  url: string
  locale?: string
}
export type ModelType = 'page' | 'generic-page' | 'blog-article'

type BuilderRequestProps = {
  limit?: number
  offset: number
  fields?: string
  contentType: ModelType
  includeUnpublished: boolean
  builderCache?: { cacheSeconds?: number; staleCacheSeconds?: number }
}
type BuilderGetAllProps = Omit<BuilderRequestProps, 'offset'>

export type PathObj = { params: Params; locale?: string; uid?: string }
export type CmsPath = Array<string | PathObj>
export type IgnoredPath = {
  path: string
  ignoreChildren?: boolean
}

// export function isParentPage(content: ExtendendBuilderContent): boolean {
//   return content?.meta?.originalContentId === undefined
// }

export function isParentPage(content: ExtendendBuilderContent): boolean {
  return content?.data?.reference?.id === undefined
}

export function getLanguageVariantsBlog({ content, originalContentId, subPath = '' }: LanguageVariantProps): LanguageVariant[] {
  const langFilter = (c: ExtendendBuilderContent): boolean => c.data?.reference?.id === originalContentId || c.id === originalContentId

  return content.filter(langFilter).map((c) => {
    const isDefault = c.id === originalContentId
    const path = c.data.slug
    const locale = c.query.filter((q) => q.property === 'locale')[0].value[0]

    return {
      isDefault,
      slug: `/${subPath}${path}`,
      locale,
    }
  })
}

export function getPageAlternatesForBuilder({ content, originalContentId }: LanguageVariantProps): PageAlternateLink[] {
  const variants = getLanguageVariantsPage({ content, originalContentId })
  const pageAlternates = convertVariantsToHrefLang({ variants, host: 'https://onceupon.photo' })
  return pageAlternates
}

export function getLanguageVariantsPage({ content, originalContentId }: LanguageVariantProps): LanguageVariant[] {
  //const langFilter = (c: ExtendendBuilderContent): boolean => c.meta?.originalContentId === originalContentId || c.id === originalContentId
  const langFilter = (c: ExtendendBuilderContent): boolean => c.data?.reference?.id === originalContentId || c.id === originalContentId
  return content.filter(langFilter).map((c) => {
    const isDefault = c.id === originalContentId
    const path = c.query.filter((q) => q.property === 'urlPath')[0].value as string
    const locale = c.query.filter((q) => q.property === 'locale')[0].value[0]
    return {
      isDefault,
      slug: path,
      locale,
    }
  })
}

export function convertVariantsToHrefLang(props: ConvertProps): PageAlternateLink[] {
  const { variants, host } = props
  const hreflangs: PageAlternateLink[] = []
  variants.forEach((variant) => {
    const slug = variant.slug === '/home' ? '' : variant.slug

    if (variant.isDefault) {
      hreflangs.push({ hreflang: variant.locale, href: `${host}${slug}` })
      hreflangs.push({ hreflang: 'x-default', href: `${host}${slug}` })

      // add hreflang for language code only, i.e. <link rel="alternate" hreflang="en" href="https://onceupon.photo">
      const languageCode = variant.locale.length > 2 ? variant.locale.slice(0, 2) : variant.locale
      hreflangs.push({ hreflang: languageCode, href: `${host}${slug}` })
    } else {
      hreflangs.push({ hreflang: variant.locale, href: `${host}/${variant.locale}${slug}` })
    }
  })

  return hreflangs
}

const getResults = async ({
  limit,
  offset,
  fields,
  builderCache,
  contentType,
  includeUnpublished,
}: BuilderRequestProps): Promise<ExtendendBuilderContent[]> => {
  return (await builder.getAll(contentType, {
    ...builderCache,
    limit,
    options: { noTargeting: true, offset, includeUnpublished },
    fields,
  })) as ExtendendBuilderContent[]
}

export const aggregateBuilderPaths = (acc: PathParam[], curr: ExtendendBuilderContent): PathParam[] => {
  // let selectedLocale = 'en-gb'
  const url = curr.data.url
  const locale = curr.query.filter((q) => q.property === 'locale')
  if (locale.length > 0) {
    const locales = (locale[0].value as string[]) ?? []
    const availableLocales: PathParam[] = locales.map((v) => ({ url, locale: v }))
    return [...acc, ...availableLocales]
  }
  return acc

  // Use this if we add support for multiple urls/page
  // return typeof url === 'string' ? [...acc, { url, locale: selectedLocale }] : [...acc, ...url.map((u) => ({ url: u, locale: selectedLocale }))]
}

export const getAllBuilderPages = async ({
  limit,
  fields,
  builderCache,
  contentType,
  includeUnpublished,
}: BuilderGetAllProps): Promise<ExtendendBuilderContent[]> => {
  let offset = 0
  const pages = await getResults({ limit, offset, fields, builderCache, contentType, includeUnpublished })
  let resultLength = pages.length
  while (pages.length === limit + offset) {
    offset += resultLength
    const nextPages = await getResults({ limit, offset, fields, builderCache, contentType, includeUnpublished })
    resultLength = nextPages.length
    pages.push(...nextPages)
  }
  return pages
}

export const getPathsFromStoryblok = async (api: Storyblok, includeUid = false): Promise<CmsPath> => {
  const storyblokPaths = []
  const { data } = await api.get('cdn/links/')
  const ignoredPaths: IgnoredPath[] = [
    { path: 'layout' },
    { path: 'about-us' },
    { path: 'help' },
    { path: 'authors', ignoreChildren: true },
    { path: 'categories', ignoreChildren: true },
    { path: 'global-form-inputs', ignoreChildren: true },
    { path: 'about-us/articles' },
  ]

  const ignoredPathsOnlyInProduction: IgnoredPath[] = [{ path: 'stories-old', ignoreChildren: true }]

  const excludePublished = (published: boolean): boolean => {
    return devEnvironment ? true : published
  }

  const exludeUid = (p: PathObj) => {
    return { params: { ...p.params }, locale: p.locale ?? 'en-gb' }
  }

  Object.keys(data.links).forEach((linkKey) => {
    const linkObject = data.links[linkKey]
    const uid = linkObject.id

    // Filter out polish urls
    linkObject.alternates = linkObject.alternates.filter((alt) => alt.lang != 'pl-pl')
    const isWorkInProgress = prodEnvironment && linkObject.slug.toLowerCase().includes('wip')

    // if the page is in the list of ignoredPaths or if the pages is WIP(work-in-progress)
    const shouldBeIgnored =
      // linkObject.slug could i.e. be 'global-form-inputs' or 'global-form-inputs/appversion' so we use the ignoreChildren property to determine how to compare path vs. slug
      ignoredPaths.some((e) => (!e.ignoreChildren && e.path === linkObject.slug) || (e.ignoreChildren && linkObject.slug.startsWith(e.path))) ||
      isWorkInProgress

    const shouldBeIgnoredOnlyInProduction =
      prodEnvironment &&
      // linkObject.slug could i.e. be 'global-form-inputs' or 'global-form-inputs/appversion' so we use the ignoreChildren property to determine how to compare path vs. slug
      ignoredPathsOnlyInProduction.some(
        (e) => (!e.ignoreChildren && e.path === linkObject.slug) || (e.ignoreChildren && linkObject.slug.startsWith(e.path))
      )

    if (!shouldBeIgnored && !shouldBeIgnoredOnlyInProduction && excludePublished(linkObject.published)) {
      // push slugs to array
      const splittedSlug = linkObject.slug === 'home' ? false : linkObject.slug.split('/')
      //console.log('splittedSlug', splittedSlug)
      storyblokPaths.push({ uid, params: { slug: splittedSlug } })

      // create additional languages
      linkObject.alternates.map((alternate: StoryBlokLinkAlternate) => {
        const splittedSlug = alternate.path === 'home' ? false : alternate.path.split('/')
        const alternateLocale = alternate.lang.toLowerCase()
        storyblokPaths.push({ uid, params: { slug: splittedSlug }, locale: alternateLocale })
      })
    }
  })
  return includeUid ? storyblokPaths : storyblokPaths.map(exludeUid)
}

// We don't translate the blog to all supported languages,
// so we need to show the blog in the default language if the user selects a non-supported language.
export const getLocaleToShowBlogIn = (locale: string): string => {
  let localeToShowBlogIn = locale
  const supportedLocales = ['en-gb', 'sv-se', 'de-de', 'nl-nl', 'fr-fr']
  if (supportedLocales.indexOf(locale) === -1) {
    localeToShowBlogIn = 'en-gb'
  }

  return localeToShowBlogIn
}

export const getNavHeaders = async (builderCache, locale: string): Promise<NavigationHeader[]> => {
  const navigation = await builder.get('navigation', {
    ...builderCache,
    options: { includeRefs: false, locale: locale, includeUnpublished: !prodEnvironment },
    userAttributes: {
      locale: locale,
    },
  })

  const navHeaders: NavigationHeader[] = navigation?.data?.navHeaders
    ?.map((header) => ({
      ...header.navHeader,
      navItems: header.navHeader.navItems?.map((item) => item.navItem)?.filter((navHeader) => !navHeader.hide),
    }))
    ?.filter((navHeader) => !navHeader.hide)

  return navHeaders
}

export const getTopBanners = async (builderCache, locale: string): Promise<TopBanner[]> => {
  const builderContent = (await builder.getAll('top-banner', {
    ...builderCache,
    options: { includeRefs: true, locale: locale, includeUnpublished: !prodEnvironment },
    userAttributes: {
      locale: locale,
    },
    query: {
      data: {
        hide: false,
      },
    },
  })) as BuilderContent[]

  const topBanners: TopBanner[] = builderContent.map((topBanner) => {
    return {
      text: topBanner.data?.text,
      url: topBanner.data?.url,
      backgroundColor: topBanner.data?.backgroundColor?.value.data.color,
      textColor: topBanner.data?.textColor?.value.data.color,
      showTrustpilotStars: topBanner.data?.showTrustpilotStars ?? false,
    }
  })

  return topBanners
}
